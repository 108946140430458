.table
  border: 1px solid #dee2e6
  border-bottom: 0
  border-right: 0
  width: 100%
  tr
    background-color: white
  th
    background-color: #f8f9fa
  td
    background-color: inherit
  th, td
    position: relative
    padding: 8px
    border: 1px solid #dee2e6
    border-top: 0
    border-left: 0
