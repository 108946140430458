@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

$toggleSidebarBtnSize: 50px
.toggleSidebarBtn
  position: absolute
  bottom: 100px
  background: $sidebar-bg-color !important
  box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.05) !important
  right: -$toggleSidebarBtnSize / 2
  width: $toggleSidebarBtnSize
  height: $toggleSidebarBtnSize
  border-radius: 50% !important
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out !important

.rotate180
  transform: rotate(180deg)

.sidebarMenuItem
  text-decoration: none
  color: black
  :global(.ps-menu-button):not(:disabled) 
    &:hover
      background-color: lighten($primary-color, 40%)

.sidebarMenuActiveItem
  @extend .sidebarMenuItem
  color: white
  svg path
    fill: white
  :global(.ps-menu-button):not(:disabled)
    background-color: $primary-color
    &:hover
      background-color: darken($primary-color, 10%)