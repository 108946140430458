@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

nav.navbar
  display: flex
  justify-content: space-between
  box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.04)
  background-color: white !important
  z-index: $z-index-navbar
  padding-left: 1rem
  padding-right: 1rem
  position: sticky
  top: 0
  @include breakpoint-up(md)
    height: $navbar-height
    max-height: $navbar-height
  .loginBtn
    width: 100%
    @include breakpoint-up(md)
      width: initial
