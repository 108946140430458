@import 'styles/variables.sass'

@mixin transition($property, $duration)
  -webkit-transition: $property $duration ease-out
  -moz-transition: $property $duration ease-out
  -o-transition: $property $duration ease-out
  transition: $property $duration ease-out

.listGroupItem
  padding: 15px 20px !important
  margin-bottom: 5px
  display: flex !important
  align-items: center
  justify-content: space-between
  border: 1px solid rgba(0, 0, 0, 0.125) !important
  border-radius: 5px !important
  transform: scale(1)
  @include transition(transform, 0.3s)
  .chevronIcon
    transform: translateX(0)
    @include transition(transform, 0.3s)
    path
      fill: #6C757D
  &:hover
    transform: scale(1.01)
    h5
      color: $primary-color
    .chevronIcon
      transform: translateX(5px)
