.row
  width: 100%
  td span
    height: 20px
  .mdCol
    span
      width: 80%
      min-width: 80px
  .smCol
    text-align: right
    span
      width: 60%
      min-width: 50px
