@import 'styles/variables.sass'
  
div.colorBox
  margin-left: 5px
  display: inline-block
  width: 15px
  height: 15px
  border: 1px solid black

.colorsTitle
  font-size: 14px