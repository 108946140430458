@import 'styles/variables.sass'

.disabled
  pointer-events: none


.inputWrapper
  position: relative
  .calendarIcon
    position: absolute
    z-index: 1
    top: 11px
    left: 12px
  
  :global(.react-datepicker__input-container)
    input, textarea
      padding-left: 32px
      padding-right: 24px
  
  :global(.react-datepicker-popper)
    z-index: 2 !important
  
  .calendar  
    border: 1px solid #ced4da
    :global(.react-datepicker__triangle)
      transform: translate3d(233px, 0px, 0px) !important
      &::before
        border-bottom-color: #ced4da !important

    :global(.react-datepicker__header )
      border-bottom-color: #ced4da

    :global(.react-datepicker__day--selected)
      background-color: $primary-color

    :global(.react-datepicker__year-dropdown-container--scroll), :global(.react-datepicker__month-dropdown-container--scroll)
      margin: 0 16px

    :global(.react-datepicker__month-read-view--down-arrow)
      top: 2px

    :global(.react-datepicker__navigation-icon)
      &::before
        top: 12px

    :global(.react-datepicker__navigation--years-upcoming)
      position: relative
      display: flex
      align-items: flex-end
      justify-content: center
      &::before
        content: ''
        display: block
        width: 0
        height: 0
        border-style: solid
        border-color: #ced4da
        border-width: 3px 3px 0 0
        height: 9px
        width: 9px
        transform: rotate(-45deg)

    :global(.react-datepicker__navigation--years-previous)
      position: relative
      display: flex
      align-items: flex-start
      justify-content: center
      &::before
        content: ''
        display: block
        width: 0
        height: 0
        border-style: solid
        border-color: #ced4da
        border-width: 0 0 3px 3px
        height: 9px
        width: 9px
        transform: rotate(-45deg)

    :global(.react-datepicker-time__caption)
      font-family: Montserrat
      font-size: 15px

    :global(.react-datepicker-time__input)
      input
        padding: 6px 12px
        font-size: 15px
        border: 1px solid #ced4da
        border-radius: 0.375em
        outline: none
        margin-left: 0

    :global(.react-datepicker__time-icon)
      color: red !important
      background-color: red !important
