@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'
  
.submitBtnWrapper
  text-align: center
  button
    width: 100%
    @include breakpoint-up(md)
      width: initial
    &:nth-child(2)
      margin-top: 16px
      @include breakpoint-up(md)
        margin-top: 0
