//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: ( sm: $sm, md: $md, lg: $lg, xl: $xl, xxl: $xxl )

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include breakpoint-up(sm) {}
@mixin breakpoint-up($breakpoint) 
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint)
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint)

    // Write the media query.
    @media (min-width: $breakpoint-value)
      @content

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include breakpoint-down(sm) {}
@mixin breakpoint-down($breakpoint)
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint)
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint)

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1))
      @content

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include breakpoint-between(sm, md) {}
@mixin breakpoint-between($lower, $upper)
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper)
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower)
    $upper-breakpoint: map-get($breakpoints, $upper)

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1))
      @content
