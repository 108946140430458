@import 'styles/variables.sass'
@import 'styles/breakpoints'


div.col
  display: flex
  align-items: flex-start
  padding: 0
  overflow-x: scroll
  .content
    flex: 1
    overflow-x: hidden
    @include breakpoint-up(md)
      padding-left: 79px
      transition: padding 0.2s ease-in-out
    .children 
      min-height: calc(100vh - $navbar-height - $footer-height) 
      flex: 1
      padding: 16px
  .sidebarOpen
    @include breakpoint-up(md)
      transition: padding 0.2s ease-in-out
      padding-left: 300px
