@import './variables.sass'
@import './breakpoints.sass'

div.w-md-initial, button.w-md-initial
  @include breakpoint-up(md)
    width: initial !important

// react-confirm-alert
.react-confirm-alert
  padding: 15px

.react-confirm-alert-overlay 
  background: rgba(150, 150, 150, 0.5)

.filters-form-container
  display: flex
  flex-direction: column
  @include breakpoint-up(lg)
    flex-direction: row
    align-items: flex-end
  .filters-form
    display: grid
    grid-template-columns: repeat(1, auto)
    @include breakpoint-up(lg)
      grid-template-columns: repeat(4, auto)
    @include breakpoint-up(xxl)
      grid-template-columns: repeat(6, auto)
    > div
      align-self: flex-end
      min-width: 250px
      padding-right: 0.5rem
  .filter-buttons
    display: flex
    align-items: flex-end
    @extend .mb-3
    @extend .gap-2
    @include breakpoint-up(md)
      justify-content: end 
    a, button
      width: 100%
      div
        display: flex
        align-items: center
        justify-content: center
        flex-wrap: nowrap
        &:first-child
          margin-right: 5px
      @include breakpoint-up(md)
        width: initial

.clickableRow
  &:hover
    td
      cursor: pointer
      background-color: #f1f2f4

