@import 'styles/variables.sass'
  
span.startBoxRightAngles
  border-top-right-radius: 0
  border-bottom-right-radius: 0
  background-color: transparent
  border-right: none

input.inputLeftAngles
  border-top-left-radius: 0
  border-bottom-left-radius: 0

input.disabledInput
  cursor: not-allowed