@import 'styles/variables'

.dropzone
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center
  position: absolute
  inset: 0
  padding: 16px
  text-align: center
  background-color: rgba(255, 255, 255, 0.8)
  border: 2px dashed $primary-color
  z-index: 1
  height: 100%
  color: gray
  cursor: pointer
  .dropzoneText
    font-size: 20px
    color: $primary-color

.fileIsOver
  background-color: white
