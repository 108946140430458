@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

.footer
  margin-bottom: 1rem
  font-size: 14px
  display: flex
  flex-direction: column
  justify-content: space-evenly
  align-items: center
  gap: 16px
  @include breakpoint-up(lg)
    font-size: 15px
    flex-direction: row
    transition: padding 0.3s ease-in-out