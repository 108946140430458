@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

.container
  justify-content: center
  flex-wrap: wrap

.input
  min-width: 40px
  height: 40px
  border-radius: 8px
  margin: 2px
  border: 1px solid #ced4da
  @include breakpoint-up(md)
    margin: 8px

.focusInput
  border: 1px solid $primary-color
