.iconButton[type="button"]
  height: 36px
  width: 36px
  padding: 6px 12px
  // border: none
  display: flex
  align-items: center
  justify-content: center
  &:hover
    svg path
      fill: white
  
.btnLoading
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
