@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

.imageContainer
  position: relative
  width: 100%
  height: 100%
  overflow: hidden
  padding-bottom: 40%
  .img
    position: absolute
    inset: 0
    left: 50%
    transform: translateX(-50%)
    height: 100%
    object-fit: contain
    width: 70%
    @include breakpoint-up(md)
      right: 0
  .loading
    position: absolute
    top: 50%
    transform: translateX(-50%)
