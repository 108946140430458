$primary-color: #6b3fdc
$second-primary-color: #1c145f
$sidebar-bg-color: white
$font-family: 'Montserrat', sans-serif

$sidebar-width: 300px
$collapsed-sidebar-width: 79px
$navbar-height: 66px
$footer-height: 40px

$sm: 576px
$md: 768px
$lg: 992px
$xl: 1200px
$xxl: 1600px

$z-index-loading-overlay: 11
$z-index-modal: 10
$z-index-navbar: 6
$z-index-sidebar-overlay: 7
$z-index-sidebar: 8

:export 
  primaryColor: $primary-color
  secondPrimaryColor: $second-primary-color
  navbarHeight: $navbar-height
  sidebarBgColor: $sidebar-bg-color
  sidebarWidth: $sidebar-width
  sidebarIndex: $z-index-sidebar
