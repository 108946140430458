@import 'styles/variables.sass'

.droppedFilesContainer
  display: flex
  flex-wrap: nowrap
  margin-bottom: 5px
  overflow-x: auto
  .droppedFile
    display: flex
    align-items: center
    justify-content: space-between
    border: 1px solid #c8c8c8
    padding: 5px
    border-radius: 10px
    max-width: 250px
    min-width: 200px
    position: relative
    height: 50px
    &.uploadFailed
      border: 1px solid #dc3545
      .progressBar
        background-color: lighten(#dc3545, 40)
    .progressBar
      position: absolute
      inset: 0
      width: 0%
      height: 100%
      background-color: lighten(#dc3545, 40)
      z-index: -1
      border-radius: 10px
      transition: width 1s
      z-index: 0
    .fileName
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    &:not(:last-child)
      margin-right: 8px
