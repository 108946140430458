@import 'styles/variables.sass'

.fullscreen
  position: absolute
  inset: 0
  display: flex
  align-items: center
  justify-content: center
  background-color: rgba(255, 255, 255, 0.8)
  z-index: $z-index-loading-overlay
  width: 100%
  height: 100%
