@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'
  
.form
  display: flex
  align-items: start
  flex-direction: column
  width: 100%
  margin-bottom: 16px
  position: sticky
  top: $navbar-height
  z-index: 2
  background-color: white
  @include breakpoint-up(md)
    flex-direction: row
    margin-bottom: 0
    & > div:not(:only-child)
      flex: 1
      margin-right: 5px
  & > button
    width: 100%
    white-space: nowrap
    @include breakpoint-up(md)
      margin-top: 32px
      width: initial
      align-items: start
