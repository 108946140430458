@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'

.header
  @include breakpoint-up(lg)
    display: flex
    align-items: center
    justify-content: flex-end
    margin-bottom: 1rem
    h1
      margin-bottom: 0
  .buttons
    display: flex
    flex-direction: column
    margin-top: 1rem
    margin-bottom: 1rem
    button
      margin-top: .5rem
      margin-bottom: .5rem
    @include breakpoint-up(md)
      flex-direction: row
      align-items: center
      justify-content: flex-end
      margin: 0
      button
        margin-top: 0
        margin-bottom: 0
        &:last-child
          margin-left: 1rem
