body,
html 
  height: 100%
  margin: 0
  font-family: $font-family

#root
  position: relative
  font-family: $font-family
  min-height: 100%
  &, * 
    box-sizing: border-box
