@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'
  
.avatar
  display: flex
  align-items: center
  justify-content: center
  color: white
  border-radius: 50%
  font-size: 16px
