@import 'styles/variables.sass'
@import 'styles/breakpoints.sass'
  
.submitBtnWrapper
  text-align: center
  margin-top: 30px
  button
    width: 100%
    @include breakpoint-up(md)
      width: initial
